import React, { useCallback, useState } from 'react'
import GoogleMapReact from 'google-map-react';
import Autocomplete from './Autocomplete';
import { METRIC_SYSTEM } from './constants';
import { trafficModelOptionToGoogleMapsOption } from './utils';
import mapStyles from './map-styles.json'
import { Box, useTheme } from '@mui/material';

const DEFAULT = {
  CENTER: {
    lat: 51.5131697,
    lng: -0.1458634,
  },
  ZOOM: 11,
}

const MARKERS = {
  FROM: 'FROM',
  TO: 'TO',
}

const Map = ({ onDistanceChange, metricSystem, trafficModel }) => {
  const theme = useTheme();
  const [GoogleMaps, setGoogleMaps] = useState(null);
  const [routesInfoWindows, setRoutesInfoWindows] = useState([])
  const [directionsRenderer, setDirectionsRenderer] = useState(null)
  const [markers, setMarkers] = useState({
    [MARKERS.FROM]: null,
    [MARKERS.TO]: null,
  })

  const calculateAndDisplayRoute = useCallback((origin, destination) => {
    if (!origin?.position || !destination?.position) {
      return;
    }
    
    // * calculate route with Google Maps Directions API
    const directionsService = new GoogleMaps.maps.DirectionsService();
    var _directionsRenderer = directionsRenderer;
    if (!_directionsRenderer) {
      _directionsRenderer = new GoogleMaps.maps.DirectionsRenderer({
        map: GoogleMaps.map,
        suppressInfoWindows: false,
      })
      setDirectionsRenderer(_directionsRenderer)
    } else {
      _directionsRenderer.setMap(null);
    }


    directionsService.route(
      {
        origin: origin.position,
        destination: destination.position,
        travelMode: GoogleMaps.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
        unitSystem: metricSystem === METRIC_SYSTEM.IMPERIAL 
          ? GoogleMaps.maps.UnitSystem.IMPERIAL
          : GoogleMaps.maps.UnitSystem.METRIC,
        drivingOptions: {
          departureTime: new Date(Date.now()),
          trafficModel: trafficModelOptionToGoogleMapsOption(trafficModel, GoogleMaps.maps.TrafficModel)
        }
      },
      (response, status) => {
      if (status === 'OK') {
          _directionsRenderer.setDirections({ routes: []});
          _directionsRenderer.setDirections(response);

          setTimeout(() => {
            onDistanceChange(response, origin.country, destination.country)
          }, 0)
          routesInfoWindows.forEach(infoWindow => infoWindow.close());
          const newInfoWindows = response.routes.map(route => {
            const infoWindow = new GoogleMaps.maps.InfoWindow();
            infoWindow.setContent(route.legs[0].distance.text + "<br>" + route.legs[0].duration.text + " ");
            infoWindow.setPosition(route.legs[0].steps[Math.floor(route.legs[0].steps.length / 2)].start_location);
            infoWindow.open(GoogleMaps.map)
            return infoWindow;
          })
          setRoutesInfoWindows(newInfoWindows)
        } else {
          window.alert('Directions request failed due to ' + status);
        }
      }
    );
  }, [GoogleMaps, directionsRenderer, metricSystem, onDistanceChange, routesInfoWindows, trafficModel])

  const onMarkerChange = useCallback((placeGeometryLocation, type, country) => {
    if (markers[type]) {
      markers[type].setMap(null)
    }

    const marker = new GoogleMaps.maps.Marker({ map: GoogleMaps.map });
    marker.setPosition(placeGeometryLocation);
    marker.country = country;
    setMarkers({ ...markers, [type]: marker });
    
    setTimeout(() => {
      const bounds = new GoogleMaps.maps.LatLngBounds();
      let bothMarkersSet = false;
      if (type === MARKERS.FROM && markers[MARKERS.TO]) {
        bounds.extend(new GoogleMaps.maps.LatLng(
          markers[MARKERS.TO].getPosition().lat(), 
          markers[MARKERS.TO].getPosition().lng()
        ))
        bothMarkersSet = true;
      } else if (type === MARKERS.TO && markers[MARKERS.FROM]) {
        bounds.extend(new GoogleMaps.maps.LatLng(
          markers[MARKERS.FROM].getPosition().lat(),
          markers[MARKERS.FROM].getPosition().lng()
        ))
        bothMarkersSet = true;
      }
      bounds.extend(new GoogleMaps.maps.LatLng(placeGeometryLocation.lat(), placeGeometryLocation.lng()))
      GoogleMaps.map.fitBounds(bounds);
      GoogleMaps.map.panToBounds(bounds);

      if (!bothMarkersSet) {
        GoogleMaps.map.setZoom(DEFAULT.ZOOM);
      } else {
        setTimeout(() => {
          const origin = {
            position: type === MARKERS.FROM ? placeGeometryLocation : markers[MARKERS.FROM].getPosition(),
            country: type === MARKERS.FROM ? country : markers[MARKERS.FROM].country,
          }
          const destination = {
            position: type === MARKERS.TO ? placeGeometryLocation : markers[MARKERS.TO].getPosition(),
            country: type === MARKERS.TO ? country : markers[MARKERS.TO].country,
          }
          calculateAndDisplayRoute(origin, destination)
        }, 0)
      }
    }, 0)
  }, [GoogleMaps, calculateAndDisplayRoute, markers])

  const onSwapMarkers = useCallback(() => {
    if (!markers[MARKERS.FROM] || !markers[MARKERS.TO]) {
      return;
    }
    const fromPosition = markers[MARKERS.FROM].getPosition();
    const toPosition = markers[MARKERS.TO].getPosition();
    const fromMarker = markers[MARKERS.FROM];
    const toMarker = markers[MARKERS.TO];
    const fromCountry = '' + markers[MARKERS.FROM].country;
    const toCountry = '' + markers[MARKERS.TO].country;

    fromMarker.setMap(null)
    fromMarker.setPosition(toPosition)
    fromMarker.country = toCountry;
    fromMarker.setMap(GoogleMaps.map)
    
    toMarker.setMap(null)
    toMarker.setPosition(fromPosition)
    toMarker.country = fromCountry;
    toMarker.setMap(GoogleMaps.map)

    setMarkers({
      [MARKERS.FROM]: fromMarker,
      [MARKERS.TO]: toMarker,
    })

    const origin = { position: toPosition, country: toCountry }
    const destination = { position: fromPosition, country: fromCountry }
    setTimeout(() => calculateAndDisplayRoute(origin, destination), 0)
  }, [GoogleMaps, markers, calculateAndDisplayRoute])

  const createOptions = useCallback(maps => {
    return {
      styles: theme.palette.mode === 'dark' ? mapStyles : undefined,
      mapTypeControl: false,
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
        style: maps.ZoomControlStyle.SMALL
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT
      },
    }
  }, [theme.palette.mode])

  return (
    <>
      <Autocomplete
        {...(GoogleMaps || {})} 
        onMarkerFromChange={(e, country) => onMarkerChange(e, MARKERS.FROM, country)}
        onMarkerToChange={(e, country) => onMarkerChange(e, MARKERS.TO, country)}
        onSwapMarkers={onSwapMarkers}
      />
      
      <div className='options'>
        {/* avoidTolls avoidHighways avoidFerries */}
      </div>

      <Box className='map-wrapper' sx={{ 
        height: '50vh',
        width: '100%',
        borderRadius: { xs: 0, lg: '30px' },
        overflow: 'hidden',
      }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_ROUTES_API }}
          defaultCenter={DEFAULT.CENTER}
          defaultZoom={DEFAULT.ZOOM}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={setGoogleMaps}
          options={createOptions}
          layerTypes={['TrafficLayer']}
        />
      </Box>
    </>
  )
}

export default Map